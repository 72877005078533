window.gptadslots = [];
window.googletag = window.googletag || {cmd:[]};

googletag.cmd.push(function() {
  //Adslot 1 declaration
  gptadslots.push(googletag.defineSlot('/21626329468/DiscoverOK', ['fluid',[300,250]], 'div-gpt-ad-2820577-1')
      .setTargeting('pos', ['1'])
      .addService(googletag.pubads()));
  //Adslot 2 declaration
  gptadslots.push(googletag.defineSlot('/21626329468/DiscoverOK', ['fluid',[300,250]], 'div-gpt-ad-2820577-2')
      .setTargeting('pos', ['2'])
      .addService(googletag.pubads()));

  googletag.pubads().enableSingleRequest();
  googletag.pubads().setForceSafeFrame(false);
  googletag.pubads().setCentering(true);
  googletag.enableServices();
});